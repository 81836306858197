<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div
          class="
            d-flex
            flex-wrap
            align-items-center
            justify-content-between
            mb-3
          "
        >
          <div class="d-flex align-items-center justify-content-between">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb p-0 mb-0">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'layout.order' }"
                    >Orders</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Add Orders
                </li>
              </ol>
            </nav>
          </div>
          <router-link
            :to="{ name: 'layout.order' }"
            class="
              btn btn-secondary btn-sm
              d-flex
              align-items-center
              justify-content-between
              ml-2
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="ml-2">Back</span>
          </router-link>
        </div>
      </div>
    </div>

    <div id="main-div">
      <div class="row">
        <div class="col-lg-6">
          <div class="card">
            <div class="col mb-3">
              <h5 class="font-weight-bold mt-3 mb-3">Customer Details</h5>

              <div class="d-flex justify-content-between">
                <label
                  for="inputState"
                  class="form-label font-weight-bold text-muted"
                  >Customer *</label
                >
                <router-link :to="{ name: 'customer-add' }">
                  <img class="mb-1" :src="plusSvg" />
                </router-link>
              </div>

              <multiselect
                v-model="customerId"
                :options="users"
                value="user"
                :custom-label="getCustomCustomer"
                track-by="id"
                placeholder="Search by customer email or phone"
                @search-change="handleSearchCustomer"
                :max="users.length"
                @select="onChangeCustomer"
                @remove="removeCustomer"
                :searchable="true"
                :internal-search="false"
              >
               <span slot="noOptions"
                  >Type at least 5 characters to search for customer </span
                >
              </multiselect>

              <label
                v-if="isCustomer"
                class="text-danger col-form-label-sm mt-1 ml-1"
                >Customer Not Selected</label
              >
            </div>

            <div class="col mb-3" v-if="customerId">
              <div class="d-flex justify-content-between">
                <label
                  for="car-model"
                  class="form-label font-weight-bold text-muted"
                  >Car *</label
                >
                <router-link :to="{ path: '/car/add/' + this.customerPhone }">
                  <img class="mb-1" :src="plusSvg" />
                </router-link>
              </div>

              <multiselect
                v-model="carId"
                :options="cars"
                :custom-label="getCustomCar"
                track-by="id"
                :placeholder="'Select Car'"
                :max="cars.length"
                @select="onChangeCar($event)"
                :searchable="true"
                :internal-search="true"
              >
                <span slot="noResult"
                  >Oops! No elements found. Consider changing the search
                  query.</span
                >
              </multiselect>

              <label v-if="isCar" class="text-danger col-form-label-sm ml-1"
                >Car Not Selected</label
              >
            </div>
          </div>

          <div class="row desktop-view" v-if="workshop">
            <div class="col">
              <div class="card">
                <div class="col mb-5" id="tax-false">
                  <h5 class="font-weight-bold mt-3 mb-3">Services</h5>

                  <div class="row mt-3">
                    <div class="col-7 d-flex flex-column">
                      <label
                        for="inputState"
                        class="form-label font-weight-bold text-muted"
                        >Name *</label
                      >
                    </div>

                    <div class="col-5 d-flex flex-column">
                      <label class="form-label font-weight-bold text-muted"
                        >Amount *
                      </label>
                    </div>
                  </div>

                  <div
                    class="row mb-3"
                    v-for="(input, index) in inputs"
                    :key="index"
                  >
                    <div class="col-7 d-flex flex-column">
                      <input 
                        v-model="input.name" 
                        class="custom-input service-name"
                        @input="handleServiceNameChange($event, index)"
                      >
                    </div>

                    <div class="col-5 d-flex flex-column">
                      <div class="d-flex">
                        <input
                          min="0"
                          class="custom-input service-amt"
                          type="number"
                          placeholder="0"
                          v-model="input.amount"
                          :disabled="isDisabledServiceAmt[index] === true ? true : false"
                          @input="handleServiceAmountChange($event, index)"
                          @keypress="isNumber($event)"
                        />


                        <img
                          v-if="inputs.length > 1"
                          @click="deleteRow(index)"
                          class="ml-2 btn-transistion service-remove"
                          :src="minusSvg"
                        />
                      </div>
                    </div>
                  </div>

                   <label
                    v-if="isServiceName"
                    class="text-danger col-form-label-sm ml-1"
                    >Service Name should be 2-32 characters</label
                  >

                  <label
                    v-if="isServiceAmount"
                    class="text-danger col-form-label-sm ml-1"
                    >Service Amount Cannot be Empty</label
                  >

                  <div class="wrap" id="btn-service">
                    <button
                      class="btn btn-sm btn-success"
                      @click="addRow"
                      id="add-service"
                    >
                      Add Service
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <input
            class="btn btn-success order-add btn-visible-web btn-sm"
            id="order-now"
            type="button"
            @click="handleCreateOrder()"
            value="Save Order"
          />
        </div>
        <div class="col-lg-6" v-if="this.carId && this.carId !== 'No cars' && this.carId != null">
          <div class="card">
            <div class="col mb-2">
              <div class="d-flex justify-content-between mt-3 mb-3">
                <h5 class="font-weight-bold">Service Details</h5>
              </div>
            </div>

            <div class="col mb-3">
              <label
                for="inputState"
                class="form-label font-weight-bold text-muted"
                >Workshop *</label
              >

              <multiselect
                v-model="workshop"
                :options="workshopsArray"
                :custom-label="getCustomWorkshop"
                placeholder="Select Workshop"
                :max="workshopsArray.length"
                @select="onChangeWorkshop"
                @open="handleWorkshop"
                @remove="removeWorkshop"
                :class="{
                  singleOptions: true,
                }"
                :show-labels="false"
                :allow-empty="true"
                :searchable="true"
                :internal-search="false"
                :clear-on-select="false"
                :close-on-select="true"
              >
              </multiselect>

              <label
                v-if="isWorkshop"
                class="text-danger col-form-label-sm ml-1"
                >Workshop Not Selected</label
              >
            </div>

            

            <div class="col mb-3" v-if="this.workshop">
              <label
                for="car-model"
                class="form-label font-weight-bold text-muted"
                >Payment Method *</label
              >
              <vue-single-select
                v-model="paymentId"
                :options="paymentMethods"
                :getOptionDescription="getCustomPaymentMethods"
                @input="onChangePaymentMethod($event)"
              >
              </vue-single-select>

              <label
                v-if="isPaymentMethod"
                class="text-danger col-form-label-sm ml-1"
                >Payment Method is not selected</label>
            </div>

            <div class="col mb-3">
              <label for="car-model" class="form-label text-muted"
                >Staff Note</label
              >

              <textarea
                class="custom-input notesArea"
                rows="2"
                v-model="staffNote"
                @input="handleStaffNote($event)"
              ></textarea>
            </div>
          </div>

          <div class="row mobile-view" v-if="workshop">
            <div class="col">
              <div class="card">
                <div class="col mb-5" id="tax-false">
                  <h5 class="font-weight-bold mt-3 mb-3">Services</h5>
                  <div
                    class="row mb-3"
                    v-for="(input, index) in inputs"
                    :key="index"
                  >
                    <div class="col-12 d-flex flex-column">
                      <label
                        for="inputState"
                        class="form-label font-weight-bold text-muted"
                        >Name *</label
                      >
                      <input 
                        v-model="input.name" 
                        class="custom-input service-name"
                        @input="handleServiceNameChange($event, index)"
                      >
                    </div>

                    <div class="mt-2 col-12 d-flex flex-column">
                      <label class="form-label font-weight-bold text-muted"
                        >Amount *
                      </label>
                      <div class="d-flex">
                        <input
                          min="0"
                          class="custom-input service-amt"
                          type="number"
                          placeholder="0"
                          v-model="input.amount"
                          :disabled="isDisabledServiceAmt[index] === true ? true : false"
                          @input="handleServiceAmountChange($event, index)"
                          @keypress="isNumber($event)"
                        />
                        
                        <img
                          v-if="inputs.length > 1"
                          @click="deleteRow(index)"
                          class="ml-2 btn-transistion service-remove"
                          :src="minusSvg"
                        />
                      </div>
                    </div>
                  </div>

                   <label
                    v-if="isServiceName"
                    class="text-danger col-form-label-sm ml-1"
                    >Service Name should be 2-32 characters</label
                  >


                  <label
                    v-if="isServiceAmount"
                    class="text-danger col-form-label-sm ml-1"
                    >Service Amount Cannot be Empty</label
                  >

                  <div class="wrap" id="btn-service">
                    <button
                      class="btn btn-sm btn-success"
                      @click="addRow"
                      id="add-service"
                    >
                      Add Service
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col">
              <div class="card">
                <div class="col-12 mb-3">
                  <h5 class="font-weight-bold mt-3">Total</h5>

                  <div class="row">
                    <div class="col-4">
                      <label for="inputState" class="mt-3 form-label text-muted"
                        >Tax
                        <input
                          type="checkbox"
                          :checked="checked"
                          value="checked"
                          @click="handleCheckTaxModal($event)"
                          class="tax-checkbox"
                        />
                      </label>

                      <input
                        class="custom-input"
                        type="number"
                        disabled
                        v-model="taxRate"
                      />
                    </div>
                    <div class="col-4">
                      <label
                        for="inputState"
                        class="mt-3 form-label f text-muted"
                        >Total (tax excl.)</label
                      >

                      <input
                        class="custom-input"
                        type="number"
                        disabled
                        v-model="serviceDiscountTotalCopy"
                      />
                    </div>
                    <div class="col-4">
                      <label for="inputState" class="mt-3 form-label text-muted"
                        >Total (tax incl.)</label
                      >

                      <input
                        class="custom-input"
                        type="number"
                        disabled
                        v-model="totalTaxInclude"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="load-spinner">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <input
      class="btn btn-success order-add btn-visible-mobile"
      type="button"
      @click="handleCreateOrder()"
      value="Add Order"
    />
  </div>
</template>

<script>
import {
  GET_WORKSHOP_CUSTOMERS,
  GET_WORKSHOP_CUSTOMER_CAR,
  MY_WORKSHOP,
  WORKSHOP_CHECK_SERVICES,
} from "../../../graphql/query";
import { WORKSHOP_ORDER_CREATE } from "../../../graphql/mutation";

export default {
  name: "OrderAdd",
  components: {},
  data() {
    return {
      customerId: null,
      carId: null,
      carModelId: null,

      paymentId: null,
      payment_id: '',
      users: [],
      cars: [],
      serviceArea: [],
      paymentMethods: [],
      services: [],

      taxRate: 0,
      first: 10,
      last: null,
      after: "",
      before: "",
      searchText: "",
      emptyCustomerName: false,
      modalShow: false,
      inputs: [
        {
          name: "",
          amount: "",
        },
      ],
      servicesArray: [],
      serviceName: [],
      serviceAmount: null,
      serviceDiscountPercentage: "",
      serviceDiscountAmount: "",
      serviceDiscountTotal: 0,
      serviceDiscountTotalCopy: 0,
      totalTaxInclude: 0,
      totalTaxInclude_data: 0,
      max: 2,

      scheduleDate: "",
      scheduleTime: "",

      scheduledAt: "",
      customerPhone: "",
      customerCarId: "",
      serviceDiscountName: "",
      staffNote: "",
      lat: "",
      lng: "",
      latLng: "",
      search: null,
      plusSvg: require("../../../assets/images/svg/plus.svg"),
      minusSvg: require("../../../assets/images/svg/minus.svg"),
      isCustomer: false,
      isCar: false,
      isWorkshop: false,
      isPaymentMethod: false,
      isLocation: false,
      isScheduling: false,
      isServiceName: false,
      isServiceAmount: false,


      errors: [],
      invalidDiscountName: false,
      order: [],
      checked: true,
      checkTaxData: false,

      isDisabledServiceAmt:[],
      totalService: 0,
      wash: [],

      workshop: null,
      workshopsArray: [],
    };
  },
  props: ["orderId"],
  beforeRouteEnter(to, from, next) {
    var phone = localStorage.getItem("customer-phone");
    if (phone) {
      next((vm) => {
        vm.handleSearchCustomer(phone).then(() => {
          vm.onChangeCustomer(vm.users[0]);
        });
        vm.search = phone;
        vm.getCustomer();
      });
    } 
    else {
      next();
    }
  },
  methods: {
    //Customers
    async getCustomer() {
      await this.$apollo
        .query({
          query: GET_WORKSHOP_CUSTOMERS,
          variables: {
            first: this.first,
            search: this.search,
          },
        })
        .then((data) => {
          let userData = data.data.workshopUsers.edges;
          this.users = userData.map((res) => {
            return res.node;
          });
          
        });
    },

    async handleSearchCustomer(event) {
      if (event) {
        this.search = event;
        if(this.search.length >= 5){
          await this.getCustomer();
        }
      }
    },

    getCustomCustomer(opt) {
      return opt.firstName + " " + opt.lastName;
    },
   
    removeCustomer(selectedOption) {
      if (selectedOption) {
        this.customerPhone = null;
        this.before = null;
        this.currentPage = 1;
        this.getCustomer();
      }
    },

    async onChangeCustomer(selection) {
      this.isCustomer = false;
      this.customerId = selection;
      this.customerPhone = selection.phone;
      localStorage.setItem("customer-phone", this.customerPhone);
      this.getCustomerCar().then(()=>{
        var cust_car = localStorage.getItem("customer-car");
        if (cust_car) {
          for (let j = 0; j < this.cars.length; j++) {
            if (this.cars[j].id === cust_car) {
              this.customerCarId = this.cars[j].id
              this.carModelId = this.cars[j].model.id;
              return (this.carId = this.cars[j]);
            }
          }
          localStorage.removeItem("customer-phone");
          localStorage.removeItem("customer-car");
        }
      })
    },


    //Car
    async getCustomerCar() {
      await this.$apollo
        .query({
          query: GET_WORKSHOP_CUSTOMER_CAR,
          variables: {
            userId: this.customerId.id,
          },
        })
        .then((data) => {
          this.cars = data.data.workshopUserCars;    
          if (this.cars.length === 1) {
            this.cars.map((res) => {
              this.carId = res;
              this.carModelId = res.model.id
              this.customerCarId = res.id
            });
            this.handleWorkshop()
          } else if (this.cars.length === 0) {
            this.carId = "No cars";
            this.customerCarId = null
          } else {
            this.carId = null;
            this.customerCarId = null
          }
        });
    },

    getCustomCar(opt) {
      if (opt.model) {
        return opt.model.make.name + " " + opt.model.name;
      } else {
        return "No cars for the selected customer. Please add new car";
      }
    },

    async onChangeCar(selection) {
      if (selection) {
        this.isCar = false;
        this.customerCarId = selection.id;
        this.carModelId = selection.model.id;
        this.inputs = [{ name: "", amount: "" }];
        this.serviceDiscountPercentage = "";
        this.serviceDiscountAmount = "";
        this.totalTaxInclude = 0;
        this.serviceDiscountTotalCopy = 0;
        this.serviceDiscountTotal = 0;
        this.serviceDiscountName = "";
        this.handleWorkshop()
      }
    },

    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    handleWorkshop() {
      this.$apollo
        .query({
          query: MY_WORKSHOP,
          variables: {
            first: this.first
          },
        })
        .then((data) => {
          this.workshopsArray = data.data.myWorkshops;
          if(this.workshopsArray.length === 1){
             this.workshopsArray.map((res) => {
              this.workshop = res;
              this.getServices()
            });
          }
        });
    },
    getCustomWorkshop(opt) {
      return opt.name;
    },
    // handleSearchWorkshop(event) {
    //   if (event) {
    //     this.searchWorkshop = event;
    //     this.handleWorkshop();
    //   }
    // },
    onChangeWorkshop(event) {
      this.workshop = event;
      this.isWorkshop = false;
      this.getServices()
    },
    removeWorkshop(selectedOption) {
      if (selectedOption) {
        this.workshop = null;
      }
    },

    async getServices() {
      this.$apollo
        .query({
          query: WORKSHOP_CHECK_SERVICES,
          variables: {
            carModelId: this.carModelId,
            workshopId: this.workshop.id,
          },
        })
        .then((data) => {
          if (data.data.workshopCheckServices) {
            this.paymentMethods = data.data.workshopCheckServices.paymentMethods
            this.taxRate = data.data.workshopCheckServices.taxRate
            this.currency = data.data.workshopCheckServices.currency
          }
        });
    },

    //Payment Method
    getCustomPaymentMethods(opt) {
      return opt.name;
    },

    async onChangePaymentMethod(selection) {
      if (selection) {
        this.isPaymentMethod = false;
        this.paymentId = selection;
        this.payment_id = selection.id;
      }
    },


    async handleServiceAmountChange(e) {
      if (e.target && e.target.value >= 0) {
        this.checked = true;
        var test_data = this.validateInput(e.target.value);
        if (test_data) {
          this.isServiceAmount = false;
          this.serviceAmount = e.target.value;

          //Getting Total values from all rows
          let total = 0;
          this.inputs.map((input) => {
            total =
              Math.round(
                (total + parseFloat(input.amount ? input.amount : 0)) * 100
              ) / 100;
          });

          // if (this.totalTaxInclude > 0 && this.serviceDiscountAmount) {
          //   this.checkTaxShow = true;
          // }
          //Setting it to state variable to access it anywhere
          this.serviceDiscountTotal = total;
          this.serviceDiscountTotalCopy = total;
          //Handle Grand Total

          this.totalTaxInclude = Math.round((total + (this.taxRate / 100) * total) * 100) / 100;

        } else {
          let total = 0;
          this.inputs.map((input) => {
            if (input.amount !== "") {
              total =
                Math.round(
                  (total + parseFloat(input.amount ? input.amount : 0)) * 100
                ) / 100;
            } else {
              this.serviceAmount = e.target.value;
            }
          });
          this.totalTaxInclude =
            Math.round((total + (this.taxRate / 100) * total) * 100) / 100;
          this.serviceDiscountTotalCopy = total;
          // this.serviceDiscountAmount = "";
          // this.serviceDiscountPercentage = "";
          // if (this.totalTaxInclude <= 0) {
          //   document.getElementById("discountAmount").disabled = true;
          //   document.getElementById("discountPercentage").disabled = true;
          // } else {
          //   document.getElementById("discountAmount").disabled = false;
          //   document.getElementById("discountPercentage").disabled = false;
          // }
        }
      } else {
        this.isServiceAmount = false;
        //Setting Value
        this.serviceAmount = e;
        if (this.serviceAmount >= 0) {

          //Getting Total values from all rows
          let total = 0;
          this.inputs.map((input) => {
            if (input.amount !== "") {
              total =
                Math.round((total + parseFloat(input.amount)) * 100) / 100;
            }
          });

          //Setting it to state variable to access it anywhere
          this.serviceDiscountTotal = total;
          this.serviceDiscountTotalCopy = total;

          //Handle Grand Total
          if (this.serviceAreaId !== null) {
            this.totalTaxInclude_data = (this.taxRate / 100) * total;
            this.totalTaxInclude =
              Math.round((total + this.totalTaxInclude_data) * 100) / 100;
          } else {
            // this.checked = true;
            this.totalTaxInclude = 0;
          }
        }
      }
    },

    validateInput(data) {
      //Regex for Valid Characters i.e. Alphabets, Numbers and Space.
      var regex = /^[0-9.]+$/;

      var isValid = regex.test(data);

      return isValid;
    },
  
    //AddDelete
    addRow(a) {

      if (a === true) {
        this.inputs.pop({
          id: "",
          name: "",
          amount: "",
        });
        this.order.lines.map((res, i) => {
          if (res.service) {
            this.inputs.push({
              id: res.service.id,
              name: res.serviceName,
              amount:  res.service.price ? res.service.price.amount :  res.price.amount,
            });
            this.onChangeServiceName(res, i);
          } else {
            this.inputs.push({
              name: res.serviceName,
              amount: res.price.amount,
            });
          }
          this.handleServiceAmountChange(res.price.amount).then(() => {
          });
        });
      } 
      else if(a === 'wash'){
        this.inputs.pop({
          id: "",
          name: "",
          amount: "",
        });

        this.inputs.push({
          name: this.wash.package.type.name,
          amount:  0
        });

      }else {
        this.inputs.push({
          name: "",
          amount: "",
        });
      }
    },
    deleteRow(index) {
      // Deleting values from total
      this.serviceDiscountTotal =
        Math.round(
          (this.serviceDiscountTotal - this.inputs[index].amount) * 100
        ) / 100;

      //Setting Copy to Total so that value should be deleted from orignal only.
      this.serviceDiscountTotalCopy = this.serviceDiscountTotal;

      //clearing Input States
      this.serviceDiscountPercentage = "";
      this.serviceDiscountAmount = "";

      //Handle Grand Discount
      this.totalTaxInclude =
        Math.round(
          (this.serviceDiscountTotalCopy +
            (this.taxRate / 100) * this.serviceDiscountTotalCopy) *
            100
        ) / 100;

      // Deleting Row
      this.inputs.splice(index, 1);
      this.serviceName.splice(index, 1);
    },

    async handleServiceNameChange(selection, index) {
      if (selection.target.value.length >=2 && selection.target.value.length <= 32){
        this.inputs[index].name = selection.target.value;
        this.isServiceName = false;
      }
      else{
        this.isServiceName = true;
      }
    },

    handleStaffNote(e) {
      this.staffNote = e.target.value;
    },

    handleCheckTaxModal(event) {
      this.checkTaxData = true;
      if (this.totalTaxInclude > 0) {
        this.checked = event.target.checked;
        this.handleYesCancelModal()
      } else {
        this.$bvToast.toast(
          `Amount value should be greater than 0 to remove tax`,
          {
            toaster: "b-toaster-top-center",
            variant: "danger",
            solid: true,
            toastClass: "text-center toast-box toast-style",
            // noAutoHide:true
          }
        );
        event.preventDefault();
        event.stopPropagation();
      }
    },

    handleYesCancelModal() {
      this.serviceDiscountName = "";
      if (this.checkTaxData) {
        this.checkTax(this.checked);
      } else {
        this.serviceDiscountAmount = "";
        this.serviceDiscountPercentage = "";
      }
      this.checkTaxData = false;
    },

    handleOfferModal() {
      this.serviceDiscountAmount = "";
      this.serviceDiscountPercentage = "";
      this.serviceDiscountName = "";
    },

    checkTax(check) {
      if (this.taxRate > 0) {
        if (check === false) {
          var tax_data = 1 + this.taxRate / 100;
          this.inputs.filter(function (input) {
            if (input.amount !== "") {
              var data = parseFloat(input.amount) / tax_data;
              return (input.amount = Math.round(data * 100) / 100);
            }
          });

          this.handleServiceAmountChange(this.serviceAmount);
          var cells = document.getElementsByClassName("service-amt");
          for (var i = 0; i < cells.length; i++) {
            cells[i].disabled = true;
          }
          document.getElementById("add-service").style.display = "none";
          var serv_remove = document.getElementsByClassName("service-remove");
          for (var sr = 0; sr < serv_remove.length; sr++) {
            serv_remove[sr].style.display = "none";
          }

          const node = document.createElement("p");
          node.setAttribute("id", "tax-disabled-text");
          const textnode = document.createTextNode(
            "Note: You cannot add/delete services untill tax is enabled."
          );
          node.appendChild(textnode);
          document.getElementById("tax-false").appendChild(node);

        } else {
          var tax_data_add = 1 + this.taxRate / 100;
          this.inputs.filter(function (input) {
            if (input.amount !== "") {
              var data = parseFloat(input.amount) * tax_data_add;
              return (input.amount = Math.round(data * 100) / 100);
              // return input.amount = (input.amount * 1.05);
            }
          });

          this.handleServiceAmountChange(this.serviceAmount);
          var cells_data = document.getElementsByClassName("service-amt");
          for (var j = 0; j < cells_data.length; j++) {
            cells_data[j].disabled = false;
          }
          document.getElementById("add-service").style.display = "block";
          var serv_remove_en =
            document.getElementsByClassName("service-remove");
          for (var sr_en = 0; sr_en < serv_remove_en.length; sr_en++) {
            serv_remove_en[sr_en].style.display = "block";
          }
          document.getElementById("tax-disabled-text").remove();
        }
      }
    },

    handleCreateOrder() {
      if (!this.customerId) {
        return (this.isCustomer = true);
      } else if (!this.carId || this.carId === "No cars") {
        return (this.isCar = true);
      } else if (!this.workshop) {
        return (this.isWorkshop = true);
      } else if (!this.paymentId) {
        return (this.isPaymentMethod = true);
      } else if (this.inputs) {
        for (let i = 0; i < this.inputs.length; i++) {
          if (this.inputs[i].name === "") {
            return this.isServiceName = true;
          }
        }
      } else if (
        this.inputs[0].amount === null ||
        this.inputs[0].amount === ""
      ) {
        return (this.isServiceAmount = true);
      }


      this.servicesArray = this.inputs.map((item) => {
          return {
            name: item.name.name ? item.name.name : item.name,
            amount: item.amount ? parseFloat(item.amount) : 0,
          };
      });


      document.getElementById("order-now").disabled = true;
      document.getElementById("add-service").disabled = true;
      document.getElementById("main-div").style.display = "none";
      document.getElementById("load-spinner").style.display = "flex";

      this.$apollo
        .mutate({
          mutation: WORKSHOP_ORDER_CREATE,
          variables: {
            customer: this.customerPhone,
            car: this.customerCarId,
            workshop: this.workshop.id,
            total: this.totalTaxInclude,
            paymentMethod: this.paymentId ? this.paymentId.id : null,
            staffNote: this.staffNote,
            services: this.servicesArray
          },
        })
        .then((data) => {
          document.getElementById("main-div").style.display = "block";
          document.getElementById("load-spinner").style.display = "none";
          if (data.data.workshopOrderCreate.errors.length == 0) {
            var id = data.data.workshopOrderCreate.order.number;
            this.$router.push({ path: "/order/" + id });
            document.getElementById("order-now").disabled = false;
            document.getElementById("add-service").disabled = false;
            localStorage.removeItem("customer-phone");
            localStorage.removeItem("customer-car");
          } else {
            document.getElementById("order-now").disabled = false;
            document.getElementById("add-service").disabled = false;
            this.errors = data.data.workshopOrderCreate.errors;
            this.errors.map((e) => {
              if (e.message == "Discount name required") {
                return (this.invalidDiscountName = true);
              }
              if (e.field == "paymentMethod") {
                // return this.invalidDiscountName = true
                this.$bvToast.toast(`${e.message}`, {
                  toaster: "b-toaster-top-center",
                  variant: "danger",
                  solid: true,
                  toastClass: "text-center toast-box toast-style",
                  // noAutoHide:true
                });
              }
              if (e.field == "services") {
                this.$bvToast.toast(`${e.message}`, {
                  toaster: "b-toaster-top-center",
                  variant: "danger",
                  solid: true,
                  toastClass: "text-center toast-box toast-style",
                  // noAutoHide:true
                });
              }
              if (e.field == "total") {
                this.$bvToast.toast(`${e.message}`, {
                  toaster: "b-toaster-top-center",
                  variant: "danger",
                  solid: true,
                  toastClass: "text-center toast-box toast-style",
                  // noAutoHide:true
                });
              }
            });
          }
        })
        .catch((error) => {
          document.getElementById("order-now").disabled = false;
          document.getElementById("add-service").disabled = false;
          document.getElementById("main-div").style.display = "block";
          document.getElementById("load-spinner").style.display = "none";
          var data_error = error.message.split(":");
          // This should log the error object but is just printing out the message
          this.$bvToast.toast(`${data_error[2]}`, {
            toaster: "b-toaster-top-center",
            variant: "danger",
            solid: true,
            toastClass: "text-center toast-box toast-style",
            // noAutoHide:true
          });
        });
    },

  },
};
</script>

<style>
.custom-input {
  display: block;
  width: 100%;
  padding: 0.375em 0.75em;
  font-size: 1em;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25em;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-sizing: border-box;
}

#modal-center-location .modal-footer {
  display: none !important;
}
.b-toast-danger.b-toast-solid .toast.toast-box,
.b-toast-success.b-toast-solid .toast.toast-box {
  padding: 13px 34px;
  margin: 0 auto;
}
.wrap {
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 1em !important;
}

.singleOptions .multiselect__tags {
  min-height: 10px !important;
}

.notesArea {
  line-height: 25px !important;
}

.multiselect__select {
  height: 38px !important;
}

.success-msg {
  font-size: 1rem;
  color: #489e04;
}

.btn-transistion {
  transition: all 0.3s ease 0s;
}
.btn-transistion:hover {
  transform: translateY(3px);
}

.tax-checkbox {
  left: 10px;
  top: 1px;
  position: relative;
}

#modal-tax___BV_modal_header_ button {
  display: none;
}

#tax-disabled-text {
  padding: 16px;
  color: red;
  font-weight: 500;
}

#load-spinner {
  justify-content: center;
  display: none !important;
}

.mobile-view {
  display: none;
}

.desktop-view {
  display: block;
}

.disabled .search-input {
  /* pointer-events: none; */
  color: #bfcbd9;
  cursor: not-allowed;
  background-image: none;
  background-color: #eef1f6;
  border-color: #d1dbe5;
}

.disabled .cursor-pointer {
  cursor: not-allowed !important;
}

.pac-container{
  z-index: 1100 !important;
}

.pac-target-input{
    background: none padding-box rgb(255, 255, 255);
    border: 2px solid #ec008c;
    padding: 0px 28px;
    cursor: pointer;
    direction: ltr;
    overflow: hidden;
    height: 40px;
    color: rgb(86, 86, 86);
    font-size: 18px;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    border-radius: 5px;
    position: absolute;
    top: 26px;
    width: 50%;
    right: 17%;;
    z-index: 1;
}

.gmap-div svg{
  position: absolute;
  top: 37px;
  z-index: 1;
  right: 64%;
}
    
@media only screen and (max-width: 991px) {
  .btn-visible-web {
    display: none;
  }
}
@media only screen and (min-width: 992px) {
  .btn-visible-mobile {
    display: none;
  }
}

@media only screen and (max-width: 650px) {
  .mobile-view {
    display: block;
  }
  .desktop-view {
    display: none;
  }
}
</style>

